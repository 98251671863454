import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import TableImageViewer from "./TableImageViewer.jsx";

const ItemTable = ({
  listItems,
  setListItems,
  tempEmblemPrices,
  setTempEmblemPrices,
  handleDeleteItem,
  handleUpdateListItem,
}) => {
  const [validStates, setValidStates] = useState({});
  const regex = /^\d{1,2}(-|\s-\s)\d{1,2}$|^\d{1,2}$/;

  useEffect(() => {
    const initialValidStates = {};
    listItems.forEach((item) => {
      initialValidStates[item.item_id] =
        item.shipping_time === "" || regex.test(item.shipping_time);
    });

    setValidStates(initialValidStates);
  }, [listItems]);

  const handleQuantity = (itemId, quantity) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,

              quantity,
            }
          : item,
      ),
    );
  };

  const handleMultiplier = (itemId, multiplier) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              multiplier,
            }
          : item,
      ),
    );
  };

  const handleToggleEditable = (itemId) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              is_editable: !item.is_editable,
            }
          : item,
      ),
    );
  };

  const handleEmblemCheckbox = (itemId, isChecked) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              is_emblem: isChecked,
              emblem_price: isChecked ? item.emblem_price : 0,
            }
          : item,
      ),
    );
  };

  const handleTempEmblemPrice = (itemId, emblemPrice) => {
    setTempEmblemPrices((prevPrices) => ({
      ...prevPrices,
      [itemId]: emblemPrice,
    }));
  };

  const handleToggleSkuVisibility = (itemId, isChecked) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              is_sku_visible: isChecked,
            }
          : item,
      ),
    );
  };

  const handleToggleDetailedPrice = (itemId, isChecked) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              is_price_detailed: isChecked,
            }
          : item,
      ),
    );
  };

  const handleShippingTimeChange = (itemId, shippingTime) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              shipping_time: shippingTime,
            }
          : item,
      ),
    );
  };

  const handleBasePrice = (itemId, basePrice) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              base_price: basePrice,
              custom_price: basePrice,
            }
          : item,
      ),
    );
  };

  const handleCustomPrice = (itemId, customPrice) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === itemId
          ? {
              ...item,
              custom_price: customPrice,
              multiplier: customPrice / item.base_price,
            }
          : item,
      ),
    );
  };

  const handleInputValidation = (itemId, value) => {
    const isValid = value === "" || regex.test(value);

    setValidStates((prevStates) => ({
      ...prevStates,
      [itemId]: isValid,
    }));

    handleShippingTimeChange(itemId, value);
  };

  return (
    <table className="w-full border-collapse rounded overflow-hidden shadow-lg">
      <thead>
        <tr className="bg-gray-200 text-sm">
          <th className="border px-4 py-2 w-28">Kép</th>
          <th className="border px-4 py-2">Név</th>
          <th
            className="border px-4 py-2 w-28"
            data-tooltip-id="table"
            data-tooltip-content="Beszerzési ár"
          >
            Beszer. ár
          </th>
          <th
            className="border px-4 py-2 w-28"
            data-tooltip-id="table"
            data-tooltip-content="Amennyiben az 'Egyedi ár'-at kézzel módosítod, abban az esetben 'Emblemázási ár' nem lesz beszorozva a 'Szorzó'-val"
          >
            Egyedi ár
          </th>
          <th className="border px-4 py-2 w-24">db</th>
          <th className="border px-4 py-2 w-24">Szorzó</th>
          <th
            className="border px-4 py-2 w-24"
            data-tooltip-id="table"
            data-tooltip-content="Elfogadott mintázatok: 1, 1-2, 9-11, 11-13"
          >
            Szállítási idő
          </th>
          <th
            className="border px-4 py-2 w-16"
            data-tooltip-id="table"
            data-tooltip-content="Emblémázva kéri-e az ügyfél?"
          >
            Em.
          </th>
          <th
            className="border px-4 py-2 w-28"
            data-tooltip-id="table"
            data-tooltip-content="Emblémázás beszerzési ára"
          >
            Em. ár
          </th>
          <th
            className="border px-4 py-2 w-16"
            data-tooltip-id="table"
            data-tooltip-content="Részletes árkimutatás jelenjen meg az ajánlatban? (Emblémázási részletek megjelenítése)"
          >
            Ár részl.
          </th>
          <th
            className="border px-4 py-2 w-16"
            data-tooltip-id="table"
            data-tooltip-content="Az ajánlatban megjelenjen a termék cikkszáma?"
          >
            SKU
          </th>
          <th className="border px-4 py-2 w-28">Összeg</th>
          <th className="border px-4 py-2 w-28">Műveletek</th>
          <Tooltip id="table" />
        </tr>
      </thead>
      <tbody>
        {listItems.map((item) => {
          const emblemMultipliedPrice = Math.ceil(
            item.emblem_price * item.multiplier,
          );
          const totalPrice =
            (item.custom_price + emblemMultipliedPrice) * item.quantity;
          const isCustomPriceVisible = item.base_price !== item.custom_price;
          return (
            <tr
              key={item.item_id}
              className="hover:bg-gray-100 text-sm transition-all duration-300 ease-in-out transform"
            >
              <TableImageViewer item={item} />
              <td className="border px-4 py-2">
                {item.name}
                <p className="font-light text-xs text-neutral-900">
                  SKU: {item.sku}
                </p>
                <p className="font-light text-xs text-neutral-900">
                  Color: {item.color}
                </p>
                <p className="font-light text-xs text-neutral-900">
                  Size: {item.size}
                </p>
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="number"
                  step={"1"}
                  defaultValue={item.base_price}
                  className="w-full border rounded p-1"
                  onChange={(e) =>
                    handleBasePrice(item.item_id, parseInt(e.target.value))
                  }
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="number"
                  step={"0.1"}
                  value={
                    isCustomPriceVisible &&
                    item.custom_price &&
                    item.custom_price
                  }
                  className="w-full border rounded p-1"
                  onChange={(e) =>
                    handleCustomPrice(item.item_id, parseFloat(e.target.value))
                  }
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="number"
                  min="1"
                  defaultValue={item.quantity || 1}
                  className="w-full border rounded p-1"
                  onChange={(e) => handleQuantity(item.item_id, e.target.value)}
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="number"
                  min="1"
                  step="0.1"
                  value={item.multiplier}
                  className="w-full border rounded p-1"
                  onChange={(e) =>
                    handleMultiplier(item.item_id, parseFloat(e.target.value))
                  }
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="text"
                  maxLength="5"
                  value={item.shipping_time || ""}
                  className={`w-full border rounded p-1 ${
                    !validStates[item.item_id]
                      ? "border-red-600 focus:ring-red-600"
                      : ""
                  }`}
                  onChange={(e) =>
                    handleInputValidation(item.item_id, e.target.value)
                  }
                  title="Elfogadott mintázatok: 1, 1-2, 9-11, 11-13"
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="checkbox"
                  checked={item.is_emblem}
                  onChange={() =>
                    handleEmblemCheckbox(item.item_id, !item.is_emblem)
                  }
                />
              </td>
              <td className="border px-4 py-2 text-center">
                {item.is_emblem && (
                  <>
                    <input
                      disabled={!item.is_editable}
                      type="number"
                      step={"10"}
                      value={
                        tempEmblemPrices[item.item_id] ?? item.emblem_price ?? 0
                      }
                      className="w-full border rounded p-1"
                      onChange={(e) =>
                        handleTempEmblemPrice(item.item_id, e.target.value)
                      }
                    />
                    <p className="text-start text-xs font-thin">
                      szorzott: {emblemMultipliedPrice}
                    </p>
                  </>
                )}
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="checkbox"
                  checked={item.is_price_detailed}
                  onChange={() =>
                    handleToggleDetailedPrice(
                      item.item_id,
                      !item.is_price_detailed,
                    )
                  }
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  disabled={!item.is_editable}
                  type="checkbox"
                  checked={item.is_sku_visible}
                  onChange={() =>
                    handleToggleSkuVisibility(
                      item.item_id,
                      !item.is_sku_visible,
                    )
                  }
                />
              </td>
              <td className="border px-4 py-2 text-center">{totalPrice}</td>
              <td className="border px-4 py-2 text-center">
                <div className="flex gap-2">
                  {item.is_editable ? (
                    <button
                      className={`px-2 py-2 rounded shadow ${
                        validStates[item.item_id]
                          ? "bg-green-500 text-white hover:bg-green-600"
                          : "bg-gray-400 text-gray-200 cursor-not-allowed"
                      }`}
                      onClick={() => handleUpdateListItem(item.item_id)}
                      disabled={!validStates[item.item_id]}
                    >
                      <img width={20} src="/image/check.png" />
                    </button>
                  ) : (
                    <button
                      className="bg-blue-500 text-white px-2 py-2 rounded shadow hover:bg-blue-600"
                      onClick={() => handleToggleEditable(item.item_id)}
                    >
                      <img width={20} src="/image/writing.png" />
                    </button>
                  )}
                  <button
                    className="bg-red-500 text-white px-2 py-2 rounded shadow hover:bg-red-600"
                    onClick={() => handleDeleteItem(item.item_id)}
                  >
                    <img width={20} src="/image/trash.png" />
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ItemTable;
