import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL, PORT } from "../constants";
import { useList } from "../contexts/ListContexts";

const SearchBar = ({ selectedListId }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addedProducts, setAddedProducts] = useState({});
  const { fetchListItems } = useList();

  const fetchResults = useCallback(async (value) => {
    if (value.length <= 2) {
      setResults([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}:${PORT}/products/search?query=${value}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setResults(response.data);
    } catch (err) {
      console.error("Error fetching search results:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchListItems();
  }, [addedProducts]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchResults(query);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [query, fetchResults]);

  const handleAddToList = useCallback(
    async (product) => {
      try {
        await axios.post(
          `${BASE_URL}:${PORT}/lists/${selectedListId}/items`,
          {
            productId: product.id,
            customPrice: product.price,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );
        setAddedProducts((prev) => ({
          ...prev,
          [product.id]: true,
        }));
      } catch (err) {
        console.error("Error adding product to list:", err);
      }
    },
    [selectedListId],
  );

  const handleClearQuery = () => {
    setQuery("");
    setResults([]);
  };

  return (
    <div className="max-w-md mx-auto mt-8 relative">
      <input
        type="text"
        placeholder="Keresés termékek között..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="w-full border rounded-full p-3 pr-12 shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {query && (
        <button
          onClick={handleClearQuery}
          className="absolute right-3 top-2/4 transform -translate-y-2/4 text-gray-500 hover:text-gray-800 transition"
        >
          ✖
        </button>
      )}
      {loading && (
        <p className="text-gray-500 mt-2 flex items-center gap-2 absolute">
          <span className="loader w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></span>
          Betöltés...
        </p>
      )}
      {results.length > 0 && (
        <ul className="border mt-2 rounded shadow-lg max-h-[35rem] overflow-y-auto absolute bg-white z-10 w-full">
          {results.map((result) => (
            <li
              key={result.id}
              className="p-3 flex items-center justify-between hover:bg-blue-50 transition"
            >
              <div className="flex items-center gap-4">
                <img
                  src={result.image_urls[0] || "https://via.placeholder.com/50"}
                  alt={result.name}
                  className="w-14 h-14 rounded-full object-cover shadow"
                />
                <div>
                  <p className="font-semibold">{result.name}</p>
                  <p className="text-sm text-gray-500">SKU: {result.sku}</p>
                  <p className="text-sm text-gray-700">
                    {`\u00c1r: ${result.price}Ft + áfa`}
                  </p>
                </div>
              </div>
              <button
                className="px-3 py-1 rounded-full transition shadow bg-blue-500 text-white hover:bg-blue-600 hover:shadow-md"
                onClick={() => handleAddToList(result)}
              >
                {addedProducts[result.id] ? "Mégegyszer" : "Hozzáad"}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
