import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { BASE_URL, PORT } from "../constants";

export const useFetchUserData = () => {
  const [userData, setUserData] = useState({});

  const fetchUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }
      const response = await axios.get(`${BASE_URL}:${PORT}/auth/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data);
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return { userData };
};
