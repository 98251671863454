import React from "react";

const PartnerContactsModal = ({
  selectedPartner,
  selectedContact,
  onClose,
  onSelect,
}) => {
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-3/4 md:w-1/2 p-4">
        <h3 className="text-lg font-semibold mb-4">Kapcsolattartók</h3>
        <ul>
          {selectedPartner.contacts.map((contact) => (
            <li
              key={contact.id}
              className={`border p-3 rounded shadow mt-2 cursor-pointer ${
                selectedContact?.id === contact.id
                  ? "bg-blue-200 hover:bg-blue-200"
                  : "bg-gray-50"
              } hover:bg-blue-50`}
              onClick={() => onSelect(contact)}
            >
              <p className="font-bold">{contact.name}</p>
              <p>
                <span className="font-semibold">Mobil:</span>{" "}
                {contact.mobil || "Nincs megadva"}
              </p>
              <p>
                <span className="font-semibold">Email:</span>{" "}
                {contact.email || "Nincs megadva"}
              </p>
            </li>
          ))}
        </ul>
        <div className="mt-4 flex justify-end">
          <button
            className="bg-gray-200 px-4 py-2 rounded shadow hover:bg-gray-300"
            onClick={onClose}
          >
            Bezárás
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartnerContactsModal;
