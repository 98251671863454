import { useState } from "react";
import axios from "axios";
import { BASE_URL, PORT } from "../constants";

export const useGeneratePDF = (selectedListId) => {
  const [loadingPDF, setLoadingPDF] = useState(false);

  const handleGeneratePDF = async () => {
    if (!selectedListId) return;
    setLoadingPDF(true);
    try {
      const response = await axios.post(
        `${BASE_URL}:${PORT}/pdf/${selectedListId}`,
        null,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `list-${selectedListId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error("Error generating PDF:", err.response?.data || err.message);
    }
    setLoadingPDF(false);
  };

  return { loadingPDF, handleGeneratePDF };
};
