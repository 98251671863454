import { useState } from "react";

const ImageGallery = ({ item }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <td className="border p-2 flex flex-col justify-center items-center">
      <img
        className="max-w-20 max-h-32 rounded shadow mb-2"
        src={item.image_urls[currentIndex]}
        alt={`${item.name} - ${currentIndex + 1}`}
      />
      <div className="flex space-x-2 mt-2">
        {item.image_urls.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index
                ? "bg-gray-800"
                : "bg-gray-200 border-[1px] border-black"
            } transition-all`}
          />
        ))}
      </div>
    </td>
  );
};

export default ImageGallery;
