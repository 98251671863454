/* eslint-disable no-undef */
const AppVersion = () => {
  const version = process.env.REACT_APP_VERSION || "N/A";
  const commitHash = process.env.REACT_APP_COMMIT_HASH || "N/A";

  return (
    <footer className="fixed bottom-0 w-full bg-gray-800 text-white py-2 text-center shadow-md">
      <p className="text-sm font-light">
        Version: <span className="font-medium">{version}</span> (Commit:{" "}
        <span className="font-medium">{commitHash}</span>)
      </p>
    </footer>
  );
};

export default AppVersion;
