import React, { createContext, useContext, useState, useCallback } from "react";
import axios from "axios";
import { BASE_URL, PORT } from "../constants";

const ListContext = createContext();

export const ListProvider = ({ children }) => {
  const [listItems, setListItems] = useState([]);
  const [selectedListId, setSelectedListId] = useState(null);
  const [tempEmblemPrices, setTempEmblemPrices] = useState({});
  const [lists, setLists] = useState([]);
  const [partnerData, setPartnerData] = useState(null);

  const fetchLists = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_URL}:${PORT}/lists`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.data.length > 0) {
        setLists(response.data[0]);
        setSelectedListId(response.data[0].id);
      }
    } catch (err) {
      console.error("Error fetching lists:", err.response?.data || err.message);
    }
  }, []);

  const fetchListItems = useCallback(async () => {
    if (!selectedListId) return;
    try {
      const response = await axios.get(
        `${BASE_URL}:${PORT}/lists/${selectedListId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      setListItems(
        response.data.map((item) => ({
          ...item,
          is_editable: false,
        })),
      );
    } catch (err) {
      console.error(
        "Error fetching list items:",
        err.response?.data || err.message,
      );
    }
  }, [selectedListId]);

  const handleDeleteItem = async (itemId) => {
    try {
      await axios.delete(
        `${BASE_URL}:${PORT}/lists/${selectedListId}/items/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      fetchListItems();
    } catch (err) {
      console.error("Error deleting item:", err.response?.data || err.message);
    }
  };

  const handleUpdateListItem = async (itemId) => {
    try {
      const item = listItems.find((item) => item.item_id === itemId);
      if (!item) return;

      const customPrice = Math.ceil(item.base_price * item.multiplier);
      const emblemPrice = Number(tempEmblemPrices[itemId]) || item.emblem_price;

      await axios.put(
        `${BASE_URL}:${PORT}/lists/${selectedListId}/items/${itemId}`,
        {
          base_price: item.base_price,
          customPrice,
          quantity: item.quantity,
          is_emblem: item.is_emblem,
          emblem_price: emblemPrice,
          multiplier: item.multiplier,
          is_sku_visible: item.is_sku_visible,
          shipping_time: item.shipping_time,
          is_price_detailed: item.is_price_detailed,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      setListItems((prevItems) =>
        prevItems.map((item) =>
          item.item_id === itemId
            ? {
                ...item,
                base_price: item.base_price,
                custom_price: customPrice,
                emblem_price: emblemPrice,
                is_editable: false,
              }
            : item,
        ),
      );

      setTempEmblemPrices((prevPrices) => {
        // eslint-disable-next-line no-unused-vars
        const { [itemId]: _, ...rest } = prevPrices;
        return rest;
      });
    } catch (err) {
      console.error(
        "Error updating custom price:",
        err.response?.data || err.message,
      );
    }
  };

  const handleUpdateList = async (listId, updates) => {
    try {
      const response = await axios.put(
        `${BASE_URL}:${PORT}/lists/${listId}`,
        updates,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      console.log("Lista frissítve:", response.data);
    } catch (error) {
      console.error(
        "Hiba a lista frissítése során:",
        error.response?.data || error.message,
      );
    }
  };

  const getPartnersWithContacts = useCallback(async (partnerId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}:${PORT}/partners/${partnerId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        },
      );
      setPartnerData(response?.data);
    } catch (err) {
      console.error("Error fetching lists:", err.response?.data || err.message);
    }
  }, []);

  const value = {
    lists,
    listItems,
    setLists,
    setListItems,
    fetchLists,
    fetchListItems,
    handleDeleteItem,
    handleUpdateListItem,
    selectedListId,
    setSelectedListId,
    tempEmblemPrices,
    setTempEmblemPrices,
    handleUpdateList,
    getPartnersWithContacts,
    partnerData,
  };

  return <ListContext.Provider value={value}>{children}</ListContext.Provider>;
};

export const useList = () => useContext(ListContext);
