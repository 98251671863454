import React from "react";
import { useNavigate } from "react-router-dom";
import { useFetchUserData } from "../hooks/useFetchUserData";
import { useList } from "../contexts/ListContexts";

const Header = () => {
  const navigate = useNavigate();
  const { userData } = useFetchUserData();
  const { setListItems } = useList();

  const handleLogout = () => {
    setListItems([]);
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="flex justify-between items-center mb-4">
      <div>
        <img
          src="/image/tomex_gift_logo.png"
          className="w-60"
          alt="gift logo"
        />
      </div>
      <div className="flex items-center space-x-4 flex-col">
        {userData && (
          <span className="text-gray-700">
            Üdv, <span className="font-semibold">{userData.name}</span>
          </span>
        )}
        <button
          className="text-sm text-gray-600 hover:text-red-500 transition duration-200 ease-in-out"
          onClick={handleLogout}
        >
          Kijelentkezés
        </button>
      </div>
    </div>
  );
};

export default Header;
