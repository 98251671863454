import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import axios from "axios";
import { BASE_URL, PORT } from "../constants";
import Header from "./Header";
import { useList } from "../contexts/ListContexts";
import { useGeneratePDF } from "../hooks/useGeneratePDF";
import { useFetchUserData } from "../hooks/useFetchUserData";
import ItemTable from "./ItemTable";
import { Tooltip } from "react-tooltip";
import PartnerSearchBar from "./PartnerSearchBar";
import { successNotify } from "../utlis/notify";

const Dashboard = () => {
  const {
    lists,
    listItems,
    setListItems,
    fetchLists,
    fetchListItems,
    handleUpdateListItem,
    handleDeleteItem,
    selectedListId,
    tempEmblemPrices,
    setTempEmblemPrices,
    handleUpdateList,
  } = useList();
  const [shippingPrice, setShippingPrice] = useState(lists.shipping_price);
  const [showShippingPrice, setShowShippingPrice] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { userData } = useFetchUserData();
  const { loadingPDF, handleGeneratePDF } = useGeneratePDF(selectedListId);
  const isGenerateButtonVisible = selectedListId && listItems.length !== 0;
  const isListItemsVisible = listItems.length > 0;

  useEffect(() => {
    fetchListItems();
  }, [fetchListItems]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  useEffect(() => {
    if (lists?.shipping_price !== undefined) {
      setShippingPrice(lists.shipping_price);
    }
  }, [lists]);

  const handleUpdateShippingPrice = async (listId, shippingPrice) => {
    try {
      await handleUpdateList(listId, { shipping_price: shippingPrice });
      successNotify("Szállítási költség sikeresen mentve!");
      setShowShippingPrice(false);
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (err) {
      console.error(
        "Hiba a szállítási költség frissítése során:",
        err.response?.data || err.message,
      );
    }
  };

  const handleDeleteAllItems = async () => {
    try {
      await axios.delete(`${BASE_URL}:${PORT}/lists/${selectedListId}/items/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      fetchListItems();
    } catch (err) {
      console.error(
        "Error deleting all items:",
        err.response?.data || err.message,
      );
    }
  };

  const totalPrice = listItems?.reduce((acc, item) => {
    const { custom_price, emblem_price, multiplier, quantity } = item;

    const itemTotal = Math.ceil(
      (custom_price + emblem_price * multiplier) * quantity,
    );
    return acc + itemTotal;
  }, 0);

  return (
    <div className="p-6">
      <Header />
      <SearchBar selectedListId={userData.id} />

      {isListItemsVisible && (
        <div className="mb-6 mt-10">
          <div className="flex justify-between items-center mb-2">
            <div className="flex gap-1">
              <h2 className="text-lg font-semibold">
                Katalógushoz hozzáadott termékek
              </h2>
              <img
                className="w-4 h-4"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Az árak forintban vannak megadva és nem tartalmaznak áfát"
                src="/image/information.png"
                alt="info"
              />
              <Tooltip id="my-tooltip" />
            </div>
            <p className="text-sm text-gray-500">
              Hozzáadott termékek:{" "}
              <span className="font-bold">{listItems.length}</span>
            </p>
          </div>
          <ItemTable
            listItems={listItems}
            tempEmblemPrices={tempEmblemPrices}
            handleDeleteItem={handleDeleteItem}
            handleUpdateListItem={handleUpdateListItem}
            setTempEmblemPrices={setTempEmblemPrices}
            setListItems={setListItems}
          />
          <div className="flex justify-between mt-10">
            <PartnerSearchBar listId={selectedListId} />
            <div className="flex mt-4 items-end flex-col">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={showShippingPrice}
                  onChange={() => setShowShippingPrice(!showShippingPrice)}
                />
                <span>Szállítási költség módosítása</span>
              </label>
              {showShippingPrice && (
                <div className="mt-4 w-40 flex flex-col gap-2">
                  <label
                    htmlFor="shippingCost"
                    className="block text-sm font-semibold mb-2"
                  >
                    Szállítási költség (Ft):
                  </label>
                  <input
                    id="shippingCost"
                    defaultValue={shippingPrice}
                    type="number"
                    className="border rounded p-2 w-full max-w-xs"
                    onChange={(e) => setShippingPrice(e.target.value)}
                  />
                  <button
                    className={"px-4 py-2 rounded bg-green-500 text-white"}
                    onClick={() => {
                      handleUpdateShippingPrice(selectedListId, shippingPrice);
                    }}
                  >
                    Ár frissítése
                  </button>
                </div>
              )}
              {successMessage && (
                <div className="text-green-500 font-semibold">
                  {successMessage}
                </div>
              )}
              <p className="text-lg font-semibold mt-2">
                Végösszeg:{" "}
                <span className="text-green-500">
                  {new Intl.NumberFormat("de-DE").format(totalPrice)} Ft + áfa
                </span>
              </p>
              <p className="text-sm font-semibold">
                + szállítási költség:{" "}
                <span className="text-green-500">
                  {new Intl.NumberFormat("de-DE").format(shippingPrice)} Ft
                </span>
              </p>
              {isGenerateButtonVisible && (
                <div className="mt-5 mb-6 flex justify-end gap-4">
                  <button
                    className={`px-4 py-2 rounded ${
                      loadingPDF
                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                        : "bg-green-500 text-white"
                    }`}
                    onClick={handleGeneratePDF}
                    disabled={loadingPDF}
                  >
                    {loadingPDF ? "PDF generálás..." : "PDF letöltés"}
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 transition-all"
                    onClick={handleDeleteAllItems}
                  >
                    Összes törlése
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
