import React, { useState, useEffect } from "react";
import axios from "axios";
import { useList } from "../contexts/ListContexts";
import { BASE_URL, PORT } from "../constants";
import PartnerContactsModal from "./PartnerContactsModal";
import "react-toastify/dist/ReactToastify.css";
import { successNotify } from "../utlis/notify";

const PartnerSearchBar = ({ listId }) => {
  const { handleUpdateList, getPartnersWithContacts, lists, partnerData } =
    useList();
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const contact = partnerData?.contacts?.find(
      (person) => person.id === lists.partner_contact_id,
    );
    setSelectedPartner(partnerData);
    if (contact) {
      setSelectedContact(contact);
    }
  }, [partnerData]);

  useEffect(() => {
    getPartnersWithContacts(lists.partner_id);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.trim().length < 3) {
        setResults([]);
        return;
      }

      const fetchPartners = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${BASE_URL}:${PORT}/partners/search`,
            {
              params: { query: searchText },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
          setResults(response.data || []);
        } catch (err) {
          console.error("Error fetching partners:", err.message);
          setResults([]);
        } finally {
          setIsLoading(false);
        }
      };

      fetchPartners();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleClearSearch = () => {
    setSearchText("");
    setResults([]);
  };

  const handleAddSelectedPartner = async (partner) => {
    try {
      await handleUpdateList(listId, { partner_id: partner.partner_id });
      setSelectedContact(null);
      setSelectedPartner(partner);
      setResults([]);
      setSearchText("");
    } catch (err) {
      console.error("Error durng add partner:", err.message);
    }
  };

  const handleDeletePartner = async () => {
    try {
      setSelectedPartner(null);
      await handleUpdateList(listId, {
        partner_id: null,
        partner_contact_id: null,
      });
    } catch (err) {
      console.error("Error deleting partner:", err.message);
    }
  };

  const handleSelectContact = (contact) => {
    console.log(contact);
    if (contact?.id === selectedContact?.id) {
      setSelectedContact(null);
      handleUpdateList(listId, { partner_contact_id: null });
      successNotify("Kapcsolattarto törölve");
    } else {
      handleUpdateList(listId, { partner_contact_id: contact.id });
      setSelectedContact(contact);
      successNotify("😎 Kapcsolattarto sikeresen elmentve");
    }
  };

  return (
    <div className="flex gap-8 mt-4">
      {/* Keresőmező és találatok */}
      <div className="flex flex-col relative">
        <input
          type="text"
          placeholder="Keresés partnerek között..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="w-80 border rounded-full p-1 pr-12 shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {searchText && (
          <button
            onClick={handleClearSearch}
            className="absolute left-72 top-5 transform -translate-y-2/4 text-gray-500 hover:text-gray-800 transition"
          >
            ✖
          </button>
        )}
        {isLoading && (
          <p className="text-gray-500 mt-2 flex items-center gap-2 absolute">
            <span className="loader w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></span>
            Betöltés...
          </p>
        )}
        {results.length > 0 && (
          <ul className="border mt-12 w-80 rounded shadow-lg max-h-[35rem] overflow-y-auto absolute bg-white z-10">
            {results.map((partner) => (
              <li
                key={partner.id}
                className="p-3 flex items-center text-black justify-between hover:bg-blue-50 transition cursor-pointer"
                onClick={() => handleAddSelectedPartner(partner)}
              >
                <div>
                  <p className="font-semibold">{partner.nev}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Kiválasztott partner adatai */}
      {selectedPartner && (
        <div className="flex-1 p-4 border rounded shadow bg-white">
          <h2 className="text-lg font-thin">Kiválasztott partner:</h2>
          <div className="mt-2">
            <p className="text-lg font-bold">{selectedPartner.nev}</p>
            <p className="text-sm text-gray-600">
              {selectedPartner.iranyitoszam} {selectedPartner.varos},{" "}
              {selectedPartner.kozterulet_neve}{" "}
              {selectedPartner.kozterulet_jellege} {selectedPartner.hazszam}
            </p>
            <p className="mt-2">
              <span className="font-semibold">Mobil:</span>{" "}
              {selectedPartner.mobil || "Nincs megadva"}
            </p>
            <p>
              <span className="font-semibold">Email:</span>{" "}
              {selectedPartner.email || "Nincs megadva"}
            </p>
            {selectedContact && (
              <p>
                <span className="font-semibold">Kapcsolattartó:</span>{" "}
                {selectedContact.name}
              </p>
            )}
          </div>
          {selectedPartner.contacts && selectedPartner.contacts.length > 0 ? (
            showModal && (
              <PartnerContactsModal
                selectedPartner={selectedPartner}
                selectedContact={selectedContact}
                onClose={() => setShowModal(false)}
                onSelect={handleSelectContact}
              />
            )
          ) : (
            <div className="flex items-center justify-center mt-4 border border-yellow-500 bg-yellow-50 text-yellow-700 p-4 rounded shadow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M12 20h0c4.418 0 8-3.582 8-8 0-4.418-3.582-8-8-8s-8 3.582-8 8c0 4.418 3.582 8 8 8z"
                />
              </svg>
              <span>Nincs kapcsolattartó megadva!</span>
            </div>
          )}
          {selectedPartner.contacts.length > 0 && (
            <button
              className="mt-4 mr-2 bg-blue-500 text-white px-3 py-1.5 text-sm rounded-md shadow-sm hover:bg-blue-600 transition-all"
              onClick={() => setShowModal(true)}
            >
              Kapcsolattartó Kiválasztása
            </button>
          )}
          <button
            onClick={handleDeletePartner}
            className="mt-4 bg-red-500 text-white px-3 py-1.5 text-sm rounded-md shadow-sm hover:bg-red-600 transition-all"
          >
            Törlés
          </button>
        </div>
      )}
    </div>
  );
};

export default PartnerSearchBar;
