import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL, PORT } from "../constants";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}:${PORT}/auth/login`, {
        email,
        password,
      });
      localStorage.setItem("token", response.data.token); // JWT token tárolása
      navigate("/dashboard"); // Átirányítás a főoldalra
    } catch (err) {
      setError("Invalid email or password");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen ">
      <div className="w-100">
        <img src="/image/tomex_group_logo.png" className="w-80" alt="logo" />
      </div>
      <div className="max-w-sm min-w-80 mx-auto mt-10 p-4 border rounded shadow">
        <h1 className="text-xl font-bold mb-4 text-center">Online Katalógus</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border rounded p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Jelszó</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border rounded p-2"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded w-full"
          >
            Bejelentkezés
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
